.our-manifesto {
    .section-subtitle {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
    }
}
.collapse-container {
    margin: 1.5rem auto;
    @include media-breakpoint-up(md) {
        margin: 2.5rem auto;
        width: 70%;
        max-width: 650px;
    }
}
.question-bloc {
    border-bottom: 1px solid rgba($black, 0.1);
    .question-row {
        padding: 1.5rem 0;
        background: white;
        transition: all .15s ease;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include media-breakpoint-up(lg) {
            align-items: center;
        }
        &:hover, &:active {
            background: $gray;
        }
        &[aria-expanded="true"] {
            background: $gray;
            .see-answer-icon {
                transform: rotate(-180deg);
                background: $primary;
                &:after {
                    background: white;
                }
                &:before {
                    width: 0;
                }
            }
        }
    }
    .question-number {
        color: $primary;
        font-size: 1.1rem;
        font-weight: 700;
        margin-right: 10px;
        line-height: 21px;
        @include media-breakpoint-up(lg) {
            font-size: 2rem;
            margin-right: 20px;
        }
    }
    .question-label {
        font-weight: 500;
        font-size: 1rem !important;
        max-width: 78%;
        flex: 1;
        @include media-breakpoint-up(md) {
            padding: 0 15px;
        }
    }
    .see-answer-icon {
        width: 20px;
        height: 20px;
        background: transparent;
        transform: rotate(-90deg);
        border-radius: 50%;
        border: 1px solid $primary;
        transition: all .2s ease;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 1px;
            width: 50%;
            background: $primary;
        }
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 50%;
            width: 1px;
            background: $primary;
        }
    }
    .question-answer {
        max-width: 93%;
        margin: auto;
        font-size: .95rem;
        color: rgba($black, .6);
        padding: 20px 0;
        .question-link {
            color: $primary;
            text-decoration: underline;
            transition: all .2s ease;
            &:hover, &:active {
                color: rgba($primary, .7);
                text-decoration: none;
            }
        }
    }
    .source-bloc {
        margin: 2rem 0 1rem;
    }
    .source-title {
        font-weight: 700;
        text-transform: uppercase;
        font-size: .8rem;
        color: rgba($black, .6);
        margin-bottom: .25rem;
    }
    .source-link {
        text-decoration: underline;
        color: $primary;
        opacity: 1;
        font-size: .9rem;
        transition: all .2s ease;
        &:hover, &:active { 
            opacity: .7;
            text-decoration: none;
        }
    }
}
.section-now {
    @include media-breakpoint-up(md) {
        padding: 4rem;
    }
    .col-headline {
        text-align: center;
        max-width: 500px;
        margin: auto;
    }
    .main-description {
        margin: 2rem auto;
        font-size: .9rem;
        color: rgba($black, .6);
    }
    .cta-container {
        margin: 2rem auto;
        @include media-breakpoint-up(md) {
            margin: 3rem auto 2rem;
        }
    }
    .btn-primary {
        width: 80%;
        padding: 12px 0;
        max-width: 290px;
    }
}