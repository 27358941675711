.section-support,
.section-past-partners {
    background: #F9F9F9;
    padding: 1rem 0.5rem;
    .container {
        .row {
            padding: 1rem 0.5rem;
        }
    }
    .section-subtitle {
        text-align: center;
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
            margin-top: 2rem;
            text-align: left;
            max-width: 230px;
        }
    }
    .separator {
        margin-bottom: 1rem;
    }
    .logos-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        max-width: 320px;
        margin: auto;
        @include media-breakpoint-up(md) {
            max-width: none;
        }
        .logo-link {
            opacity: 1;
            transition: all .2s ease;
            width: 35%;
            text-align: center;
            &:hover, &:active {
                opacity: .5;
            }
            @include media-breakpoint-up(md) {
                width: 18%;
            }
            @include media-breakpoint-up(lg) {
                width: 17%;
            }
            @include media-breakpoint-up(xl) {
                width: 16%;
            }
        }
        img {
            padding: 3px 2px;
        }
    }
}