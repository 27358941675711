.section-social {
    background: $black;
    padding: 3rem 0;
    text-align: center;
    @include media-breakpoint-up(md) {
        padding: 4rem 0;
    }
    .social-title {
        font-size: 1.3rem;
        font-weight: 700;
        /* text-transform: uppercase; */
        color: $white;
        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 3rem;
        }
    }
    .cta-container {
        display: block;
    }
    .social-link {
        font-size: .75rem;
        font-weight: 700;
        color: $white;
        text-transform: uppercase;
        opacity: 1;
        text-decoration: underline;
        transition: all .2s ease;
        @include media-breakpoint-up(lg) {
            font-size: .9rem;
        }
        &:hover {
            opacity: .7;
            text-decoration: none;
        }
    }
}
.section-links {
    .section-text {
        font-size: .9rem;
        color: rgba($black, .5);
        .hashtags {
            font-weight: 700;
            color: $primary;
        }
    }
    .btn-dark {
        margin: 2rem 0;
        display: inline-flex;
        align-items: center;
        .icon-twitter {
            margin-right: 13px;
        }
    }
    .row-footer {
        align-items: center;
        justify-content: space-around;
    }
    .col-help-us {
        @include media-breakpoint-up(md) {
            max-width: 450px;
            margin: 0rem auto 1.5rem;
            text-align: center;
        }
        @include media-breakpoint-up(lg) {
            text-align: left;
            margin-bottom: 0;
        }
    }
    .col-links {
        width: 50%;
        min-width: 170px;
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 1.5rem;
        @include media-breakpoint-up(md) {
            justify-content: space-between;
            width: auto;
            max-width: 90%;
            margin: 0 auto;
        }
    }
    .footer-links-title {
        font-weight: 700;
        color: $primary;    
        letter-spacing: 0.5px;
        font-size: .8rem;
        text-transform: uppercase;
    }
    .footer-links-list {
        margin: .75rem 0;
        li {
            margin: .5rem 0;
        }
        .footer-link {
            font-size: .9rem;
            padding: 1rem 0;
            color: rgba($nav-color, .5);
            transition: all .2s ease;
            &:hover, &:active {
                color: rgba($nav-color, .2);
            }
        }
    }
}