.our-impact {
    .main-subtitle {
        li {
            margin-top: 10px;
            margin-left: 80px;
        }
    }
    .section-hero {
        padding-bottom: 20px;
    }

    .hero-link {
        color: $primary;
        text-decoration: underline;
        transition: all .2s ease;
        &:hover, &:active {
            color: rgba($primary, .7);
            text-decoration: none;
        }
    }
    .row-articles {
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        .col-article {
            margin-bottom: 2rem;
            @include media-breakpoint-up(md) {
                margin-bottom: 4rem;
            }
        }
    }

    .section-awareness {
        padding: 1rem .5rem;
        padding-top: 50px;
        &.anchored {
            padding: calc(2rem + 40px) 15px 0;
            @include media-breakpoint-up(lg) {
                padding: calc(2rem + 70px) 2rem 0;
            }
        }
        .col-headline {
            max-width: 1140px;
            margin-top: 20px;
            margin: 0 auto 3rem;
        }
        .section-text {
            margin-left: 80px;
            margin-top: 15px;
        }
    }

    .section-timeline {
        padding: 0.1rem .5rem;
        .col-headline {
            max-width: 1140px;
            margin: 0 auto 0;
        }
        .section-text {
            margin-left: 80px;
            margin-top: 15px;
        }
    }

    .section-regulation {
        padding: 0.1rem .5rem;
        .col-headline {
            max-width: 1140px;
            margin: 0 auto 3rem;
        }
        .section-text {
            margin-left: 80px;
            margin-top: 15px;
        }
        .description-link {
            font-weight: 700;
            color: $primary;
            transition: all .2s ease;
            &:hover, &:active {
                color: rgba($primary, .7);
                text-decoration: underline;
            }
        }
        .reference-title {
            font-style: italic;
            color: $primary;
        }
    }
}