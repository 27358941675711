.section-hero {
    .row {
        align-items: center;
    }
    .col-visual {
        text-align: center;
        margin-top: 3rem;
        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
        img {
           max-width: 70%;
        }
    }
    .main-subtitle {
        margin: 1rem 0;
        @include media-breakpoint-up(lg) {
            margin: 2rem 0;
        }
    }
    .btn-primary {
        width: 80%;
        padding: 12px 0;
        max-width: 350px;
    }
}
.section-description {
    @include media-breakpoint-up(lg) {
        padding: 3rem 3rem 5.5rem 3rem;
    }    
    .row-description {
        max-width: 620px;
        margin: auto;
        text-align: left;
        @include media-breakpoint-up(md) {
            text-align: center;
        }    
        .section-text {
            margin: 2rem auto 0;
            font-size: 1.1rem;
            color: rgba($black, .6);
        }
    }
}
.section-take-action {
    @include media-breakpoint-up(lg) {
        padding: 5rem 0 0 0;
    }    
    .headline {
        text-align: center;
        @include media-breakpoint-up(md) {
            margin-bottom: 1rem;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 1rem;
        }
    }
    .row-actions {
        text-align: center;
        margin: 2rem auto 0;
        .col-12 {
            margin-bottom: 3rem;
        }
    }
    .col-icon {
        max-width: 60px;
    }
    .col-title {
        font-weight: 500;
        font-size: 1rem;
        margin: 1.5rem auto 1rem;
        max-width: 255px;
    }
    .btn-dark {
        width: 200px;
        font-size: 0.85rem;
    }
}
.section-help-us {
    max-width: 500px;
    margin: auto;
    margin-bottom: 3rem;
    .img-container {
        text-align: center;
        margin-bottom: 2rem;
    }
    .section-text {
        margin: 1rem 0;
        font-size: 1.1rem;
        color: rgba($black, .6);
    }
    .description-link {
        font-weight: 700;
        color: $primary;
        transition: all .2s ease;
        &:hover, &:active {
            color: rgba($primary, .7);
        }
    }
    .hashtags {
        font-weight: 700;
        color: $primary;
    }
    .btn-dark {
        margin: 2rem 0;
        .icon-twitter {
            margin-right: 13px;
        }
    }
}
.main-banner {
    width: 95%;
    height: 500px;
    background-image: url('../../assets/images/youtube-network-banner.jpg');
    background-position: center center;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -40px;
}