.our-partners-list {
    margin-bottom: 2rem;
    .partner-el {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: calc(1rem + 40px);
        @include media-breakpoint-up(lg) {
            flex-direction: row;
            justify-content: space-evenly;
            padding-top: calc(1rem + 70px);
        }
        .list-text {
            @include media-breakpoint-up(md) {
                width: 80%;
            }
            @include media-breakpoint-up(lg) {
                width: 70%;
            }
        }
    }
    .section-subtitle {
        text-transform: uppercase;
        font-weight: 700;
        font-size: .9rem;
        letter-spacing: .5px;
        color: $primary;
    }
    .section-text {
        font-size: .9rem;
        color: rgba($black, .5);
    }
    .logo-container {
        margin-bottom: 2rem;
        .logo-partner {
            max-width: 120px;
            max-height: 90px;
        }
    }
    .list-link {
        color: $primary;
        text-decoration: underline;
        transition: all .2s ease;
        &:hover, &:active {
            color: rgba($primary, .7);
            text-decoration: none;
        }
    }
}