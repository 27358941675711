.section-newspaper {
    padding: 2rem 0;
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
        padding: 2rem 3rem;
        margin-bottom: 4rem;
    }
    .section-subtitle {
        text-align: center;
        max-width: 620px;
        margin: auto;
    } 
    .youtube-form {
        margin: 2rem .5rem;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        @include media-breakpoint-up(lg) {
            margin: 3rem auto;
        }
    }
    .form-group {
        @include media-breakpoint-up(md) {
            width: 40%;
        }
    }
    .select-label {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 0.7rem;
        letter-spacing: 0.5px;
        font-weight: 600;
    }
    .form-control.select {
        border: none;
        border-bottom: 1px solid $black;
        border-radius: 0;
        color: rgba($black,.5);
        padding-left: 0;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url("../../assets/images/icon-select.svg") no-repeat center right;
        background-size: 15px;
        opacity: 1;
        transition: all .2s ease;
        cursor: pointer;
        &:hover, &:active {
            opacity: .5;
        }
        &:focus {
            box-shadow: none;
        }
    }
    .headline {
        width: 100%;
        text-align: center;
    }
    .newspaper-container {
        background: $light;
        padding: 1rem 0;
        @include media-breakpoint-up(lg) {
            max-width: 1140px;
            margin: auto;
        }
    }
    .newspaper-title {
        font-family: $news-title-font;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.6rem;
        max-width: 90%;
        margin: 1rem auto;
        @include media-breakpoint-up(md) {
            font-size: 1.8rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 2.3rem;
        }
    }
    .newspaper-date {
        background: $nav-color;
        font-family: $news-date-font;
        color: $white;
        margin: .5rem 0;
        padding: .5rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .5px;
        font-size: .8rem;
    }
    .row-videos {
        margin: 2.5rem auto 0;
        @include media-breakpoint-up(md) {
            margin: 2.5rem 1rem 0
        }
    }
    .col-video {
        margin-bottom: 1.5rem;
    }
    .iframe-container {
        overflow: hidden;
        border-radius: 10px;
        height: 190px;
        @include media-breakpoint-up(lg) {
            height: 270px;
        }
        @include media-breakpoint-up(xl) {
            height: 340px;
        }
        iframe {
            width: 100%;
            height: 190px;
            @include media-breakpoint-up(lg) {
                height: 270px;
            }
            @include media-breakpoint-up(xl) {
                height: 340px;
            }
        }
    }
    .video-title {
        margin-top: .75rem;
        font-size: 1rem;
        font-weight: 700;
    }
    .video-details {
        font-size: .8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba($black,.5);
        .text-accent {
            font-weight: 700;
        }
    }
    .col-link {
        margin-top: 1.5rem;
        text-align: center;
    }
    .learn-how-link {
        font-weight: 700;
        text-transform: uppercase;
        font-size: .8rem;
        opacity: 1;
        text-decoration: underline;
        transition: all .2s ease;
        img {
            margin-right: 5px;
            max-width: 20px;
        }
        &:hover {
            opacity: .7;
            text-decoration: none;
        }
    }
}