header {
    position: sticky;
    top: 0;
    z-index: 1000;
}
.navbar {
    .container {
        max-width: 1450px;
    }
    background: rgba( $white, 0.95 );
    // border-bottom: 1px solid rgba($black, 0.1);
    @include media-breakpoint-up(lg) {
        height: 70px;
        border-bottom: none;
        box-shadow: 0 3px 12px 0 rgba($black, 0.08);
    }
    .navbar-nav {
        margin: 1rem 0;
        @include media-breakpoint-up(lg) {
            align-items: center;
            margin: 0 0 0 auto;
        }
        .nav-link {
            font-weight: 600;
            color: $nav-color;
            display: inline-block;
            font-size: .9rem;
            transition: all .2s ease;
            @include media-breakpoint-up(lg) {
                padding: 0 10px;
            }
            &:hover, &:active {
                color: rgba($nav-color, .6);
            }
            &[aria-current] {
                color: $primary;
            }
        }
        .btn-primary {
            margin: 1rem 0;
            @include media-breakpoint-up(lg) {
                margin: 1rem 0 1rem 1.5rem;
            }
        }
    }
}
.navbar-brand {
    filter: none;
    transition: all .3s ease;
    &:hover, &:active {
        filter: blur(1.5px);
    }
}
.navbar-toggler {
    border: none;
    width: 50px;
    height: 45px;
    z-index: 5;
    position: relative;
    padding: 0;
    &:focus {
        outline: none;
    }
    &[aria-expanded=true] {
        .menu-mobile-icon {
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
            &:nth-child(2) {
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
            }
            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
    .menu-mobile-icon {
		display: block;
	    background: rgba($black, .8);
        position: absolute;
        left: 20%;
        width: 60%;
	    height: 2px;
	    transform: rotate(0deg);
        transition: .2s ease-in-out;
        &:nth-child(1) {
            top: 65%;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 49%;
        }
        &:nth-child(4) {
            top: 33%;
        }
    }
}
.switch-lang {
    display: none;
    .collapse-list {
        padding: 0.75rem 0;
    }
}