.section-timeline {
    .col-headline {
        position: relative;
        margin: 0 auto 3rem;
        max-width: 280px;
        @include media-breakpoint-up(md) {
            max-width: 380px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 510px;
        }
    }
    .timeline-list {
        position: relative;    
        margin: 4rem 0 0;
        &:before {
            content: " ";
            height: 100%;
            width: 1px;
            background-color: rgba($nav-color, .3);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            @include media-breakpoint-up(lg) {
                left: 2.5em;
            }
        }
    }
    .row-point {
        position: relative;
        padding-left: 2rem;
        margin-bottom: 3rem;
        &.extended {
            margin-bottom: 1.2rem;
        }
        &.key:before {
            content: "";
            width: 11px;
            height: 11px;
            position: absolute;
            top: 0;
            left: 10px;
            border-radius: 50%;
            background: $primary;
            z-index: 1;
            @include media-breakpoint-up(lg) {
                left: 50px;
            }
        }
        &:last-child {
            padding-bottom: 2rem;
            @include media-breakpoint-up(lg) {
                padding-bottom: 5rem;
            }
            &:before {
                content: " ";
                width: 11px;
                height: 11px;
                box-sizing: border-box;
                position: absolute;
                bottom: 0;
                left: 10px;
                border-radius: 50%;
                background: $primary;
                z-index: 1;
                @include media-breakpoint-up(lg) {
                    left: 50px;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            padding-left: 0;
        }
    }
    .col-description {
        font-size: 1rem;
        color: rgba($black, .6);
        padding-top: 1rem;
        max-width: 500px;
        margin: auto;
    }
    .col-year {
        position: relative;
        .dot-line {
            @include media-breakpoint-up(lg) {
                background-color: rgba($nav-color, .3);
                width: 40%;
                height: 1px;
                position: absolute;
                top: 5px;
                right: 6%;
                &:before {
                    content: "";
                    width: 11px;
                    height: 11px;
                    position: absolute;
                    top: -5px;
                    left: 0;
                    border-radius: 50%;
                    background: $primary;
                    z-index: 1;
                }
                &:after {
                    content: "";
                    width: 11px;
                    height: 11px;
                    position: absolute;
                    top: -5px;
                    right: 0;
                    border-radius: 50%;
                    background: $primary;
                    z-index: 1;
                }
            }
        }
    }
    .year {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 12px;
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
            font-size: 1.4rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 1.6rem;
            position: relative;
            left: 21%;
        }
    }
    .cell-title {
        text-transform: uppercase;
        color: $primary;
        font-weight: 700;
        font-size: .9rem;
        letter-spacing: .5px;
        margin-bottom: .75rem;
        @include media-breakpoint-up(lg) {
            font-size: .9rem;
        }
    }
    .algotransparency-action,
    .youtube-response {
        font-size: .9rem;
        color: $black;
        margin: 2rem 0;
    }
    .col-algo {
        position: relative;
        .arrow-icon {
            position: absolute;
            left:0;
            bottom: -30%;
            @include media-breakpoint-up(md) {
                right: 25px;
                bottom: initial;
                left: initial;
                top: 260px;
            }
        }
    }
    .col-youtube {
        padding-left: 3rem;
        @include media-breakpoint-up(md) {
            padding-left: 0;
            padding-top: 5rem;
            &.extended {
                padding-top: 0;
            }
        }
    }
    .last-row {
        justify-content: center;
        .main-description {
            font-size: .9rem;
            color: rgba($black, .6);
        }
        .btn-primary {
            margin: 2rem auto 1rem;
            width: 80%;
            padding: 12px 0;
            max-width: 290px;
        }
    }
}