.take-control-list {
    .take-control-el {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 2rem;
        @include media-breakpoint-up(md) {
            justify-content: space-evenly;
        }
        .list-text {
            @include media-breakpoint-up(md) {
                width: 80%;
                &.with-image {
                    margin-bottom: 1rem;
                }
            }
            @include media-breakpoint-up(xl) {
                &.with-image {
                    display: flex;
                    img {
                        padding-left: 15px;
                    }
                }
            }
        }
    }
    .section-text {
        font-size: .9rem;
        color: rgba($black, .5);
    }
    .icon-container {
        margin-bottom: .5rem;
    }
    .list-link {
        color: $primary;
        text-decoration: underline;
        transition: all .2s ease;
        &:hover, &:active {
            color: rgba($primary, .7);
            text-decoration: none;
        }
    }
    .list-why {
        font-weight: 700;
        text-transform: uppercase;
        font-size: .8rem;
        color: rgba($black, .5);
        margin-bottom: .5rem;
    }
}