.methodology-and-data {
    .category-title {
        margin-bottom: 2rem;
    }
    .col-headline {
        .section-subtitle {
            font-size: 1.1rem;
            @include media-breakpoint-up(md) {
                font-size: 1.3rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 1.7rem;
            }
        }
    }
}
.section-identify,
.section-determine {
    @include media-breakpoint-up(lg) {
        padding: calc(2rem + 80px) 3rem 2rem;
    }
    .col-text {
        color: rgba($black, .5);
        font-size: .9rem;
    }
}
.section-determine {
    @include media-breakpoint-up(lg) {
        padding-bottom: 9rem;
    }
}
.section-data-downloads {
    .downloads-title {
        font-weight: 700;
        text-transform: uppercase;
        font-size: .9rem;
        color: rgba($black, .5);
        margin-bottom: .25rem;
    }
    .download-type {
        font-weight: 700;
        font-size: .8rem;
        text-transform: uppercase;
        letter-spacing: .5px;
    }
    .list-container {
        margin-top: 1rem;
    }
}