/* layout */
body {
    font-size: 16px;
    font-weight: 400;
    font-family: $stack-font;
    color: $text-color;
    &.fixed {
        position: fixed;
        width: 100%;
    }
    /* font smoother */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    @-moz-document url-prefix() {
        font-weight: lighter !important;
    }
}
::-moz-selection { /* Code for Firefox */
    color: white;
    background: $primary;
}
::selection {
    color: white;
    background: $primary;
}
/* images */
img {
    max-width: 100%;
}
/* list */
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
/* link */
a, a:hover, a:active {
    text-decoration: none;
    color: initial;
}
/* span accent */
.text-accent {
    color: $primary;
} 
/* section */
.section {
    padding: 2rem .5rem;
    @include media-breakpoint-up(lg) {
        padding: 2rem 3rem;
    }
}
.section-hero,
.section.anchored {
    padding: calc(2rem + 40px) .5rem 2rem;
    @include media-breakpoint-up(lg) {
        padding: calc(2rem + 70px) 3rem 4rem;
    }
}
/* media-icon */
.media-icon {
    width: 20px;
}
/* remove ugly outline */
*:focus, *:active {
    outline: none;
}
/* dots-separator */
.dots-separator {
    background: url(../../assets/images/bg-dots.svg) no-repeat center center;
    background-size: 300%;
    height: 80px;
    width: 100%;
    @include media-breakpoint-up(md) {
        background-size: 150%;
    }
    @include media-breakpoint-up(lg) {
        background-size: cover;
    }
}
/* btn */
.btn-primary {
    font-size: .9rem;
    font-weight: 600;
    margin: auto;
    padding: 8px 24px;
}
.btn-dark {
    background: $black;
    font-size: .9rem;
    font-weight: 600;
    padding: 8px 24px;
}
/* shared styles */
.main-title {
    font-size: 2.1rem;
    font-weight: 400;
    @include media-breakpoint-up(md) {
        font-size: 2.8rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 3.8rem;
    }
}
.main-subtitle {
    font-size: 1.05rem;
    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
    }
}
.section-subtitle {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.4;
    @include media-breakpoint-up(md) {
        font-size: 1.8rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 2.3rem;
    }
}
.cell-link {
    &:hover {
        .img-container {
            img {
                transform: scale(1.05);
            }
        }
        .article-link,
        .team-social-link {
            opacity: .7;
        }
    }
    .img-container {
        overflow: hidden;
        height: 100%;
        border-radius: 10px;
        transform: translateZ(0);
        img {
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
            max-height: 400px;
            transition: all .4s ease;
            will-change: transform;
        }
    }
}
.carousel-cell {
    .cell-link {
        .img-container {
            img {
                height: 250px !important;
            }
        }
    }
}
.article-date {
    color: rgba($black,.5);
    font-size: .8rem;
    font-weight: 500;
    margin: 1rem 0 .25rem;
    display: block;
}
.article-link,
.team-social-link {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    opacity: 1;
    transition: opacity .4s ease;
}
.article-source {
    display: flex;
    align-items: center;
    color: rgba($black,.5);
    font-weight: 500;
    text-transform: uppercase; 
    margin: .5rem 0;
    font-size: .8rem;
    img {
        max-width: 17px;
        margin-right: 6px;
    }
    a {
        color: rgba($black,.5);
        font-weight: 500;
        text-transform: uppercase; 
        margin: .5rem 0;
        font-size: .8rem;
        transition: color .2s ease;
        &:hover, &:active {
            color: rgba($black,1);
        }
    }
}
.centered {
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}