// Fonts
$stack-font:         "Inter", sans-serif;
$news-title-font:    "Playfair Display", serif;
$news-date-font:     "Roboto Condensed", sans-serif;
// Colors
$primary:          #2C9B87;
$text-color:       #000000;
$nav-color:        #192926;
$light:            #F6F6F6;
$gray:             #F4F4F4;
$x-gray:           #FBFBFB;
$white:            #FFFFFF;
$black:            #000000;
