
.section-latest {
    @include media-breakpoint-up(md) {
        padding: 3rem 2rem; 
    }
    @include media-breakpoint-up(lg) {
        padding: 5rem 2rem 3rem; 
    }
    &.bg-primary {
        background: $primary;
        color: $white;
        .article-date,
        .article-source {
            color: rgba($white,.5);
        }
        .article-link {
            color: $white;
        }
    }
    .headline {
        text-align: center;
        @include media-breakpoint-up(md) {
            max-width: 620px;
            margin: 0 auto 2rem;
        }
        @include media-breakpoint-up(lg) {
            margin: 0 auto 2rem;
        }
    }
}
.carousel-container {
    max-width: 1450px;
    margin: auto;
}
.carousel {
    padding: 0;
    margin: 2rem auto 4rem;
    @include media-breakpoint-up(lg) {
        margin: 1rem auto 2rem;
    }
  
    &.flickity-enabled {
        position: static;
    }
    .flickity-viewport {
        transition: height 0.2s;
        right: -.5rem;  
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
        @include media-breakpoint-up(md) {
            right: -2rem;
        }
    }
    .carousel-cell {
        width: 260px;
        height: 400px;
        margin-right: .75rem;
        @include media-breakpoint-up(md) {
            width: 400px;
            height: 400px;
            margin-right: 1rem;
        }
    }
}