.row-team {
    margin-top: 1.5rem;
    .col-team {
        margin-bottom: 2rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 4rem;
        }
    }
}
.team-social-link {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 1.5rem 0 .25rem;
    .social-icon {
        margin-left: 10px;
        transition: all .4s ease;
    }
}
.team-description {
    color: rgba($black, .5);
    font-size: .9rem;
    .reference-title {
        font-style: italic;
    }
}

.team-read-more {
    margin: 1rem 0;
    .read-more-link {
        text-transform: uppercase;
        text-decoration: underline;
        opacity: 1;
        font-weight: 700;
        font-size: .8rem;
        transition: all .2s ease;
        &:hover, &:active { 
            opacity: .7;
            text-decoration: none;
        }
    }
}